<template>
<div>
  <div class="gray-header">
    <div class="header-box inner">
        <router-link to="/login"><p>로그인</p></router-link>
        <router-link to="/" style="color: #71717A"><p>홈으로 이동</p></router-link>
    </div>
  </div>
  <div class="white-header">
    <div class="header-box inner">
      <router-link to="/" class="header-logo">
        <img src="@/assets/images/IA/header_logo_1.png" alt="">
      </router-link>
      <img src="@/assets/images/IA/header_logo_2.png" alt="">
      <div class="hideBtn">
        <a href="http://www.nrc.go.kr/nrc/main.do" class="left" target="_blank"/>
        <a href="https://www.jj.ac.kr/ot/" class="right" target="_blank"/>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "HeaderAddUser",
  data() {
    return {
    }
  },
}
</script>

<style scoped>

.gray-header {
  height: 48px;
  background: #F0F0F0;
}
.gray-header .header-box {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.gray-header .header-box a {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-left: 40px;
}
.white-header { 
  height: 120px;
  box-sizing: border-box;
  border-bottom: #E1E3E6 solid 1px;
}
.white-header .header-box {
  height: 100%; width: 1140px;
  margin: 0 auto;
  position: relative;
}
.white-header .header-box .header-logo {
  position: absolute;
  top: 41px;
}
.white-header .header-box .header-logo img{
  width: 314px;
  height: 59px;
}
.white-header .header-box > img {
  position: absolute;
  right: 0; top: 46px;
  width: 217px;
  height: 54px;
}
.white-header .header-box .hideBtn {
  position: absolute;
  display: flex;
  right: 0; top: 46px;
  width: 217px;
  height: 54px;
}
.white-header .header-box .hideBtn .left {
  width: 130px;
}
.white-header .header-box .hideBtn .right {
  width: 87px;
}
</style>
