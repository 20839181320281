<template>
  <div class="main">
    <HeaderAddUser/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import HeaderAddUser from "@/components/HeaderAddUser";

export default {
  name: "AddUserView",
  components: {HeaderAddUser, Footer}
}
</script>

<style scoped>
.main {
  min-width: 1140px;
  margin: 0 auto;
}
</style>
